import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initGetResults } from "../redux/actions/scenariosActions";
const useRecommendations = () => {
  const { results, isLoading, error } = useSelector(state => state.scenariosReducer);
  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);
  const [isErrorPricing, setIsErrorPricing] = useState(false);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(initGetResults())
  }, [])

  useEffect(() => {
    if (error) {
      setIsErrorPricing(error?.detail)
    }
  }, [error])
  const handlePricePage =()=>{
    setIsErrorModalOpened(true);
  }
  return {
    results,
    isErrorModalOpened,
    isLoading,
    isErrorPricing,
    handlePricePage
  };
};

export default useRecommendations;
