import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import herroPic from "../../assets/herroPic.png";
import Home1 from "../../assets/home1.png";
import WaveWithLetters from "../../assets/Wavebluewithletters.png";
import WaveWithLettersMedium from "../../assets/WaveWithLettersMedium.png";
import WaveWithLettersSmall from "../../assets/waveblueLettersSmall.png";
import Home2 from "../../assets/home2.png";
import Home3 from "../../assets/home3.png";
import Home4 from "../../assets/home4.png";
import wave from "../../assets/wave.svg";
import Feedback from "./components/Feedback/Feedback";
import Carousel from "../Carousel";
import SpeechBubble from "./components/SpeechBubble/SpeechBubble";
import Error from "../../assets/error.svg";

import "./Home.scss";
import useNavigation from "../../hooks/useNavigation";
import useWindowWidth from "../../hooks/useWindowWidth";
import { Success } from "../../modals";

const Home = () => {
  const { isAuth, user, getIsPaidPlan, error } = useNavigation();
  const navigate = useNavigate();
  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);
  const handleLetsStart = () => {
    if(error?.detail){
      setIsErrorModalOpened(error?.detail)
    }else{
      navigate(isAuth ? "/scenario/0" : "/signin");
    }
    //   if (getIsPaidPlan()) {
    //   navigate(isAuth ? "/scenario/0" : "/signin");
    // } else {
    //   if (!isAuth) {
    //     navigate("/signin");
    //   } else {
    //     navigate("/pricingplan");
    //   }
    // }
  };
  const { width } = useWindowWidth();

  const armanFirstSpeechText = (
    <>
      Hi I'm <span>Arman</span>. Growing up in Central Florida, I found myself
      lost in a maze of future possibilities. I wanted to make sure that I could
      follow my passion and feel fulfilled in my life when I stepped into the
      real world. But let’s face it, in this rapidly changing world, figuring
      out personal success and carving out a path that genuinely reflects who we
      are is more challenging than ever. That's why my journey—and ours at
      Career Scoops—began.
    </>
  );

  const armanSecondSpeechText = `The truth is honestly scary—three-quarters of high school
  students feel all over the map with their unpreparedness when it
  comes to making big decisions about college or careers. For
  those heading to college, the idea of having to change majors is
  really intimidating, not to mention the difficult decision for
  those unsure about pursuing higher education at all.
  Post-graduation, these decisions don’t become any easier or less
  confusing, with many still grappling with which direction to
  take. This realization wasn't just an observation; it was a call
  to action.`;

  const armanThirdSpeechText = `Career Scoops goes beyond just helping with decision-making;
  it's a comprehensive journey of self-discovery and recognizing
  your untapped potential, designed to guide you toward a future
  that excites and truly resonates with you. For students and
  career explorers ready to find a path that feels tailor-made for
  you, I invite you to join Career Scoops today and take a deep
  dive into self-discovery, guided by innovative technology and
  real-world wisdom, focusing on what truly matters for your
  future.`;

  const armanFourthSpeechText = `Are you a professional passionate about mentoring young people
  in our community? Become a Community Partner and help shape the
  futures of the new generation. Joining Career Scoops means more
  than just offering support; it's an active investment in young
  lives seeking guidance and direction. Together, we can build a
  community where every young person can find their place and
  purpose in the world!`;

  return (
    <section className="Home">
      <div className="Home-welcome">
        <div className="Home-welcome-content">
          <div className="Home-welcome-content-text">
            <div className="Home-welcome-content-text__title">
              <h1>
                Discover Your Passion and Build
                <span>Your Future</span>
              </h1>
            </div>
            <p>
              Finding your dream career is similar to finding your favorite ice
              cream flavor: you need to sample different options to discover
              what suits you best. We are all unique, and it's only by exploring
              various paths that we can uncover the career that resonates with
              our individual tastes and preferences.
            </p>
            {user?.role === "Mentor" ? (
              <></>
            ) : (
              <button onClick={handleLetsStart}>Let's Start</button>
            )}
          </div>
          <div className="Home-welcome-content__img">
            <img src={herroPic} alt="" />
            <p>
              Are you here to <span>EXPLORE ?</span>
            </p>
          </div>
        </div>
      </div>
      <img
        src={
          width <= 600
            ? WaveWithLettersSmall
            : width <= 991
              ? WaveWithLettersMedium
              : WaveWithLetters
        }
        alt="WaveWithLetters"
        className="waveimg"
      />
      <div className="Home-content">
        <h3>Our Story</h3>
        <div className="Home-content-items">
          <div className="item">
            <img src={Home1} alt="" />
            <SpeechBubble
              altText="armanFirstSpeechText"
              speechText={armanFirstSpeechText}
            />
          </div>
          <div className="item">
            <img src={Home2} alt="" />
            <SpeechBubble
              altText="armanSecondSpeechText"
              speechText={armanSecondSpeechText}
            />
          </div>
          <div className="item">
            <img src={Home3} alt="" />
            <SpeechBubble
              altText="armanThirdSpeechText"
              buttonLink="/new/student/"
              buttonText="Career Explorer Sign Up"
              floatSide="right"
              hasButton
              speechText={armanThirdSpeechText}
            />
          </div>
          <div className="item">
            <img src={Home4} alt="" />
            <SpeechBubble
              altText="armanFourthSpeechText"
              buttonLink="/signup/mentor"
              buttonText="Community Partner Sign Up"
              floatSide="left"
              hasButton
              speechText={armanFourthSpeechText}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "100px 0 0 0",
          }}
        >
          <p style={{ width: "656px", fontSize: "36px", color: "#174273" }}>
            Discover your match among{" "}
            <span style={{ fontWeight: "700", fontSize: "48px" }}>800+</span>{" "}
            career options tailored just for you
          </p>
        </div>
        <Carousel />
      </div>
      <Success
            image={Error}
            title=""
            text={isErrorModalOpened}
            isOpened={isErrorModalOpened}
            close={() => {
              navigate("/pricingplan");
            }}
          />
      <Feedback />
    </section>
  );
};

export default Home;
