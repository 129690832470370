import React from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/Logo.svg";
import userImg from "../../assets/prodileIco.svg";
import useNavigation from "../../hooks/useNavigation";
import arrowLeft from "../../assets/arrowBottom.svg";

import "./Navigation.scss";
import useWindowWidth from "../../hooks/useWindowWidth";

const Navigation = () => {
  const {
    checked,
    isAuth,
    user,
    isProfileChildRoute,
    pageName,
    handleChangeInp,
    handleChangePage,
    navLinkStyles,
    handleProfileClick,
    navigateBack,
    getPricingPlanInfo,
  } = useNavigation(true);
  const { isMobile } = useWindowWidth();
  console.log(user);
  return isAuth && isMobile && isProfileChildRoute ? (
    <nav className="profile-Navigation">
      <div className="profile-Navigation__iconWrapper" onClick={navigateBack}>
        <img
          src={arrowLeft}
          alt="arrowback"
          className="profile-Navigation__iconWrapper__img"
        />
      </div>
      <p className="profile-Navigation__pageName">{pageName}</p>
    </nav>
  ) : (
    <nav className="Navigation">
      <input
        checked={checked}
        onChange={handleChangeInp}
        hidden
        id="menu__toggle"
        type="checkbox"
      />
      <div className="Navigation-content">
        {isMobile && isProfileChildRoute ? (
          <div
            onClick={navigateBack}
            className="profile-Navigation__iconWrapper"
          >
            <img
              src={arrowLeft}
              alt="arrowback"
              className="profile-Navigation__iconWrapper__img"
            />
          </div>
        ) : (
          <NavLink
            onClick={handleChangePage}
            to={"/"}
            className="Navigation-content__logo"
          >
            <img src={Logo} alt="" />
          </NavLink>
        )}
        <div className="Navigation-content__items">
          <NavLink
            style={navLinkStyles}
            className="Navigation-content__items__item"
            to={"/stories"}
          >
            Stories
          </NavLink>
          <NavLink
            style={navLinkStyles}
            className="Navigation-content__items__item"
            to={"/about"}
          >
            About Us
          </NavLink>
          <NavLink
            style={navLinkStyles}
            className="Navigation-content__items__item"
            to={"/faq"}
          >
            FAQ
          </NavLink>
          {isAuth && user?.role !== "Mentor" && !user?.affiliate_code && !user?.pricing_plan && (
            <NavLink
              style={navLinkStyles}
              className="Navigation-content__items__item"
              to={"/pricingplan"}
            >
              Pricing Plan
            </NavLink>
          )}
          <a
            target={"_blank"}
            style={{ ...navLinkStyles }}
            className="Navigation-content__items__item"
            href={"https://careerscoops.ai"}
            rel="noreferrer"
          >
            For Organizations
          </a>
          <a
            target={"_blank"}
            style={{ ...navLinkStyles }}
            className="Navigation-content__items__item"
            href={"https://careerscoops.ai/blog"}
            rel="noreferrer"
          >
            Blog
          </a>
        </div>
      </div>
      {!isAuth ? (
        <div className="Navigation-buttons">
          <Link to={"/signin"} className="Navigation-buttons__login">
            Login
          </Link>
          <Link to={"/signup"} className="Navigation-buttons__signup">
            Sign Up
          </Link>
        </div>
      ) : (
        <div onClick={handleProfileClick} className="Navigation-user">
          <img src={user?.profile_image ? user?.profile_image : userImg} alt="" />
          <div className="Navigation-user__info">
            <p>
              {user?.first_name || user?.last_name
                ? user?.first_name + " " + user?.last_name
                : user?.email}
            </p>
            {getPricingPlanInfo()}
          </div>
        </div>
      )}
      <label className="Navigation-menu" htmlFor="menu__toggle">
        <span></span>
      </label>
      <div className="menu__box">
        <div className="menu__box-top">
          <NavLink
            onClick={handleChangePage}
            style={navLinkStyles}
            className="menu__box-link"
            to={"/stories"}
          >
            Stories
          </NavLink>
          <NavLink
            onClick={handleChangePage}
            style={navLinkStyles}
            className="menu__box-link"
            to={"/about"}
          >
            About Us
          </NavLink>
          <NavLink
            onClick={handleChangePage}
            style={navLinkStyles}
            className="menu__box-link"
            to={"/faq"}
          >
            FAQ
          </NavLink>
          {isAuth && user?.role !== "Mentor" && !user?.affiliate_code && (
            <NavLink
              onClick={handleChangePage}
              style={navLinkStyles}
              className="menu__box-link"
              to={"/pricingplan"}
            >
              Pricing Plan
            </NavLink>
          )}
          <a
            target={"_blank"}
            style={{ ...navLinkStyles }}
            className="menu__box-link"
            href={"https://careerscoops.ai"}
            rel="noreferrer"
          >
            For Organizations
          </a>
          <a
            target={"_blank"}
            style={{ ...navLinkStyles }}
            className="menu__box-link"
            href={"https://careerscoops.ai/blog"}
            rel="noreferrer"
          >
            Blog
          </a>
        </div>
        {isAuth ? null : (
          <div className="menu__box-bottom">
            <Link
              onClick={handleChangePage}
              to={"/signin"}
              className="menu__box-btn"
            >
              Login
            </Link>
            <Link
              onClick={handleChangePage}
              to={"signup"}
              className="menu__box-btn"
            >
              Sign Up
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation;