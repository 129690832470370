import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getResultsSuccess,
  initGetResults,
  initSetResults,
} from "../redux/actions/scenariosActions";
import { LocalStorage } from "../common/localStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { initGetProfile } from "../redux/actions";
import { authIsAuthenticated } from "../redux/selectors/authSelectors";

const useResults = (resultsPage) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);
  const { profile } = useSelector((state) => state.profileReducer);
  const { results, isLoading, isSetResultsSuccessLoaded, error } = useSelector(
    (state) => state.scenariosReducer
  );
  const [chosenItem, setChosenItem] = useState({
    id: "",
    text: "",
    ai: "",
    score: null,
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [isSuccessModalOpened, setIsSuccessModalOPened] = useState(false);
  const isAuth = useSelector((state) => authIsAuthenticated(state));
  useEffect(() => {
    const storedAnswers = LocalStorage.getFromLocalStorage("scenarios");
    if (!storedAnswers && !isAuth) {
      resultsPage && navigate("/signin?redirect=/results");
    } else {
      dispatch(initGetResults());
      dispatch(initGetProfile());
    }
  }, [isAuth, resultsPage, navigate, dispatch]);

  useEffect(() => {
    if (error) {
      setIsErrorModalOpened(error?.detail);
    }
  }, [error]);
  useEffect(() => {
    let timer1 = null;
    if (!isLoading && (!results || !results?.length)) {
      timer1 = setTimeout(() => {
        dispatch(initGetResults());
      }, 10000);
    }
    return () => {
      timer1 && clearInterval(timer1);
    };
  }, [results]);
  const handleModalOpen = (id) => {
    setIsModalOpened(true);
    setChosenItem(() => {
      return {
        ...results?.find((el) => id === el.id),
        // score: null,
      };
    });
  };
  const closeModal = (id) => {
    const recommendId = id || chosenItem.id
    setIsModalOpened(false);
    setChosenItem("");
    handleUpdateResult(recommendId)
    chosenItem.score &&
      dispatch(
        initSetResults({
          id: recommendId,
          score: chosenItem.score,
          booked: false,
        })
      );
  };
  const handleUpdateResult = (id) => {
    const res = results.map((items) => {
      if (items.id === id) {
        items = {
          ...items,
          score: chosenItem.score,
        }
      }
      return items
    })
    dispatch(getResultsSuccess(res))
  }
  const handlescore = (rate) => {
    setChosenItem({
      ...chosenItem,
      score: rate,
    });
    let item = {
      ...chosenItem,
      score: rate,
    };
    const newSelectedItems = selectedItems.filter((el) => {
      if (el.id === item.id) {
        return false;
      } else {
        return true;
      }
    });
    setSelectedItems([...newSelectedItems, item]);
  };

  const handleBack = (id) => {
    chosenItem.score &&
      dispatch(
        initSetResults({
          id: id || chosenItem.id,
          score: chosenItem.score,
          booked: false,
        })
      );
    navigate(-1);
  };

  const handleBook = (id, booked = true) => {
    setIsModalOpened(false);
    setIsSuccessModalOPened(true);
    if (profile.pricing_plan || profile.affiliate_code) {
      const recommendId = id || chosenItem.id
      handleUpdateResult(recommendId)
      dispatch(
        initSetResults({
          id: recommendId,
          score: chosenItem.score ? chosenItem.score : 0,
          booked: booked,
        })
      );
    } else {
      navigate("/pricingplan");
    }
  };

  useEffect(() => {
    if (isSetResultsSuccessLoaded) {
      if (resultsPage) {
        // setIsSuccessModalOPened(true);
      }
      if (results?.length) {
        // LocalStorage.removeFromLocalStorage("scenarios");
      }
    }
  }, [isSetResultsSuccessLoaded, results]);
  const closeSuccessModal = () => {
    if (pathname.includes("profile")) {
      navigate("/profile/recommendations");
    }
    setIsSuccessModalOPened(false);
  };
  return {
    results,
    isModalOpened,
    chosenItem,
    isSuccessModalOpened,
    selectedItems,
    isErrorModalOpened,
    setIsErrorModalOpened,
    handleBack,
    handleModalOpen,
    closeModal,
    handlescore,
    handleBook,
    closeSuccessModal,
  };
};

export default useResults;
